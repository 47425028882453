import * as React from "react"
import "twin.macro"
import { Link } from "gatsby"

export default function FAQ() {
  return (
    <div tw="relative py-16 bg-white overflow-hidden">
      <div tw="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div tw="relative h-full text-lg max-w-prose mx-auto">
          <svg
            tw="absolute top-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  tw="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            tw="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  tw="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            tw="absolute bottom-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  tw="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div tw="relative px-4 sm:px-6 lg:px-8">
        <div tw="text-lg max-w-prose mx-auto mb-6">
          <h1 tw="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Frequently Asked Questions
          </h1>
        </div>
        <div tw="prose prose-lg text-gray-500 mx-auto">
          <h3>Q: What is the difference between FBLA and DECA?</h3>
          <ul>
            <li>
              <b>Projects</b>: MVFBLA provides our members with the opportunity
              to join projects, where you’ll gain real-world experience in the
              business and tech world. We offer three distinct projects:
              American Enterprise, Community Service, and Partnership with
              Business. Through these projects, students have the opportunity to
              network directly with business professionals, create their own
              business plans and implement them in the real-world, give back to
              the community, gain internship opportunities, and more! Learn more
              about projects <Link to="/projects">here</Link>.
            </li>
            <li>
              <b>Family/Support</b>: MVFBLA heavily emphasizes our community and
              family atmosphere throughout the entire year. To do so, we make
              sure to hold regular socials, member meetings, and bonding
              activities such as color teams and member of the month in order to
              recognize and appreciate our members.
              <br /> Furthermore, for team competitions, while DECA sends
              multiple teams from Monta Vista to each conference, MVFBLA ensures
              that we have only one team competing in each event. In this way,
              MVFBLA is able to dedicate more time and resources to each
              team/competitor, allowing for more specialized attention and
              eliminating competition within the chapter.
            </li>
            <li>
              <b>Underclassmen success/Teams</b>: While DECA does allow their
              members to choose their own teams and competitions, this limits
              the opportunities that underclassmen have. As mentioned before,
              each FBLA school chapter is only allowed to send one team to
              conferences. While this does mean that we have an interview and
              selection process in making the teams, ultimately, competitions
              officers work to assign members to competitions based off factors
              like interests, experience, work style, etc. We especially make
              sure to pair experienced upperclassmen with newer members in order
              to pass on knowledge and experience year to year, ensuring that no
              single competitor is left to struggle on their own. FBLA’s
              experienced officers and members—many of whom have placed at the
              national level— are purposely paired with underclassmen in order
              to provide mentorship and give underclassmen a better chance of
              doing well! As a result, FBLA freshmen are more likely to succeed
              in their events.
            </li>
          </ul>

          <h3>Q: Do you have to be in a business class to join FBLA?</h3>
          <p>
            Unfortunately, in order to compete in competitions at conferences,
            you must be taking a CTE-certified business class in the same school
            year. Monta Vista offers multiple different business classes you can
            choose from, including Principles of Business, International
            Business, Principles of Marketing, Business Law, and Money and
            Banking.
          </p>
          <p>
            However, even if students are not in a business class during the
            school year, students may still participate in projects events and
            be involved in projects committees—which is just as valuable as
            competing!
          </p>

          <h3>Q: What conferences does MVFBLA have?</h3>
          <p>
            MVFBLA has three main conferences every year: Bay Section Leadership
            Conference (BSLC), State Leadership Conference (SLC), and National
            Leadership Conference (NLC). The general timeline for these events
            are as follows: BSLC in early February, SLC in mid April, and NLC
            from late June to early July. Besides competing, members have the
            opportunity to network with FBLA members from other schools across
            the nation, attend career-focused workshops, and make lasting
            memories with other MVFBLA members!
          </p>
          <p>
            Another conference that FBLA offers is the Leadership Development
            Institute (LDI), often held during fall, and depending on the dates
            of the conference, MVFBLA may or may not attend in the coming year.
            However, LDI is different from the other conferences in that members
            are not actually competing— rather, it’s a great opportunity to
            attend workshops and meet FBLA members from other chapters in a more
            carefree, less competitive atmosphere.
          </p>

          <h3>Q: How will MVFBLA be operating during the COVID-19 lockdown?</h3>
          <p>
            In compliance with public health policy, we will be holding all of
            our events online, most likely over Zoom. Member meetings, socials,
            and study sessions are all still set to take place. More information
            will be sent out through email soon.
          </p>

          <p>
            While this does mean that strictly in-person events such as company
            and school tours will likely not be occurring, the officer team is
            currently planning many fun and interactive online alternatives!
          </p>

          <p>
            Unfortunately, we still do not yet know if the 2021 conferences
            (BSLC, SLC, and NLC) will be held in-person or online. Hopefully, if
            we all socially distance and wear masks, lockdown will be over in no
            time!
          </p>

          <h3>
            Q: What if I’m not interested in business? Should I still join
            MVFBLA?
          </h3>
          <p>
            Of course! Besides business-oriented competitions, MVFBLA is also
            provides opportunities for fostering leadership and networking
            skills. From perfecting the art of public speaking to learning how
            to construct a sleek & effective presentation and getting
            resume/interview tips, you’ll learn crucial interpersonal and
            corporate skills that will drive you to succeed in college and
            beyond.
          </p>
          <p>
            Through our <Link to="/projects">projects</Link> branch, MVFBLA also
            offers many opportunities to create an impact on your community,
            such as planning unique city-wide events, volunteering at local
            organizations, and even starting your own nonprofit!
          </p>
          <p>
            And if your interests lie in STEM fields and you’re looking to
            showcase your knowledge, you may compete in one of our many
            technology <Link to="/competitions">competitions</Link>. These
            include: Mobile App Development, Website Design, Digital Video
            Production, Computer Game & Simulation Programming, and many more!
          </p>
          <p>
            In fact, while a good number of our alumni are studying and working
            in business, many are also currently at top schools pursuing majors
            in STEM fields, such as Computer Science, Data Science, Biology,
            Electrical Engineering, and more!
          </p>
          <h3>Q: How does MVFBLA stack up against other chapters?</h3>
          <p>
            MVFBLA has been consistently ranked top 6 in the nation for
            consecutive years now. With our experienced mentors and
            comprehensive resources, we’ll set you up for success at the
            national level! Visit our Success page (coming soon!) to learn more.
          </p>
          <h3>Q: I’m an incoming freshman, how do I join FBLA?</h3>
          <p>
            Once the school year begins, we’ll be presenting in the business
            classes offered at Monta Vista and introducing you to MVFBLA! During
            this opportunity, members can fill out the membership form. If
            you’ve missed this, you can also email{" "}
            <a href="mailto:mvfbla@gmail.com" target="_blank">
              mvfbla@gmail.com
            </a>{" "}
            for the form or stop by one of our meetings Thursday’s at lunch in
            C110. Or, you can fill out this form{" "}
            <a
              href="https://docs.google.com/forms/d/1BNUeHHumL-zVV2479Rqio8eb4z8rMYBdQufHABmIqac/edit"
              target="_blank"
            >
              here
            </a>
            .
          </p>
          <h3>
            Q: What competitions does MVFBLA offer and which one will suit me?
          </h3>
          <p>
            For more information regarding specific competitions, you can check
            out the <Link to="/competitions">Competitions</Link> page.
            Additionally, feel free to reach out to any competition officer and
            they’ll be willing to help!
          </p>
          <h3>Q: How much of a time commitment is FBLA?</h3>
          <p>
            Besides the weekly meetings every Thursday lunch, the amount of time
            that you can put into FBLA can be flexible! Some weeks you might be
            able to put in a lot, and other weeks you might not be able to put
            in any, which is perfectly fine. Regardless of the amount of time
            that you can/are willing to put in, we have plenty of officers and
            resources available to help you succeed!
          </p>
        </div>
      </div>
    </div>
  )
}
