import * as React from "react"
import "twin.macro"

export default function Stats() {
  return (
    <div tw="bg-gray-50 pt-12 sm:pt-16">
      <div tw="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div tw="max-w-4xl mx-auto text-center">
          <h2 tw="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
            FBLA By The Numbers
          </h2>
          <p tw="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
            We are proud about the success of our underclassmen, whom we
            prioritize highly in our club.
          </p>
        </div>
      </div>
      <div tw="mt-10 pb-12 bg-white sm:pb-16">
        <div tw="relative">
          <div tw="absolute inset-0 h-1/2 bg-gray-50" />
          <div tw="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div tw="max-w-4xl mx-auto">
              <dl tw="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                <div tw="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt
                    tw="order-2 mt-2 text-lg leading-6 font-medium text-gray-500"
                    id="item-1"
                  >
                    of members at Nats place Top 3
                  </dt>
                  <dd
                    tw="order-1 text-5xl leading-none font-extrabold text-blue-600"
                    aria-describedby="item-1"
                  >
                    60%
                  </dd>
                </div>
                <div tw="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt tw="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    place top 10 in Nation
                  </dt>
                  <dd tw="order-1 text-5xl leading-none font-extrabold text-blue-600">
                    80%
                  </dd>
                </div>
                <div tw="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt tw="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    of underclassmen at Natsplace top 10 Nationally
                  </dt>
                  <dd tw="order-1 text-5xl leading-none font-extrabold text-blue-600">
                    83%
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
